<template>
  <div>
    <!-- INVENTORY LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class>Vendors</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- v-if="isTally == 1" -->
            <ul class="list-inline px-4">
              <li class="list-inline-item font-semibold project-menu-dropdown">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <span class="flex cursor-pointer font-semibold">
                    Tally Sync Options : {{ selectedTallyOption }}
                    <feather-icon
                      icon="ChevronDownIcon"
                      svgClasses="h-4 w-4"
                      class="ml-1"
                    ></feather-icon>
                  </span>
                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item
                      selected
                      @click="onSelectTallySyncOption('All')"
                      >All</vs-dropdown-item
                    >
                    <vs-dropdown-item
                      @click="onSelectTallySyncOption('Approved')"
                      >Approved</vs-dropdown-item
                    >
                    <vs-dropdown-item @click="onSelectTallySyncOption('Synced')"
                      >Synced</vs-dropdown-item
                    >
                    <vs-dropdown-item
                      @click="onSelectTallySyncOption('Unsynced')"
                      >Unsynced</vs-dropdown-item
                    >
                  </vs-dropdown-menu>
                </vs-dropdown>
              </li>
            </ul>

            <!-- Darshan | 23rd Oct, 2020 -->

            <vx-tooltip text="Sync to Tally">
              <vs-button class="px-4 mr-2" @click.native="syncVendor()">
                <span class="d-inline-flex pr-5 text-white">Sync to Tally</span>
                <!-- <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    /> -->
              </vs-button>
            </vx-tooltip>

            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <vx-tooltip text="Export Excel">
                <!-- Before Select Project -->
                <template v-if="!this.project_value.project_id">
                  <vs-button class="px-4" @click.prevent="selectProject">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </template>
                <!-- After Select Project -->
                <template v-if="this.project_value.project_id">
                  <a
                    :href="
                      excelurl +
                      `/v1/inventory/excel/download?${temp_token}&project_id=${this.project_value.project_id}`
                    "
                    target="_blank"
                  >
                    <vs-button class="px-4">
                      <span class="d-inline-flex pr-5 text-white">Export</span>
                      <img
                        src="@/assets/images/icons/export-excel.svg"
                        class="d-inline-flex"
                        height="20"
                        width="20"
                      />
                    </vs-button>
                  </a>
                </template>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="vendorsData"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import InventoryService from "@/services/inventoryService.js";
import ProjectService from "@/services/projectService.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
    AgGridVue,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>KOZO</span>`,
    },
  },
  props: ["listType"],
  data() {
    return {
      excelurl: BASEURL,
      msg: "",
      totalPages: 0,
      overlayLoadingTemplate: null,
      vendorsData: [],
      User: "Select User",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      form: {
        project_id: null,
      },
      projects: [],
      project_value: [],
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      contacts: contacts,
      columnDefs: [],
    };
  },
  beforeMount() {
    // eventBus.$on("refreshInvenoryTable", e => {
    //   this.getAllInventoryList();
    // });
    this.columnDefs = [
      {
        headerName: "Project",
        field: "expense_name",
        filter: false,
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: "Item",
        field: "category_name",
        filter: false,
        minWidth: 350,
      },
      {
        headerName: "Vendor",
        field: "quantity",
        filter: false,
        width: 250,
      },
      {
        headerName: "Category",
        field: "city_name",
        filter: false,
        width: 200,
        sortable: false,
      },
      {
        headerName: "Subcategory",
        field: "inventory_id",
        filter: false,
        width: 120,
      },
      {
        headerName: "Deal Value",
        field: "inventory_id",
        filter: false,
        width: 110,
      },
      {
        headerName: "Paid/Unpaid Value",
        field: "inventory_id",
        filter: false,
        width: 110,
      },
      {
        headerName: "Edit ",
        field: "inventory_id",
        filter: false,
        width: 110,
      },
    ];
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getAllInventoryList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    syncVendor() {
      this.$vs.loading();
      axios
        .get(`${BASEURL}/v2/tally/syncVoucherToTally`)
        .then((response) => {
          this.$vs.notify({
            // title: "Duplicate entries have not been imported.",
            title: "Updated!",
            text: "Sync Successfully",
            // time:8000,
            time: 2000,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    onSelectTallySyncOption: function (syncType) {
      this.selectedTallyOption = syncType;

      if (syncType == "Unsynced") {
        this.columnDefs = [
          {
            headerName: "Approve/Reject",
            field: "purchase_id",
            filter: false,
            width: 175,
            pinned: "left",
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: editPoOrder,
            // cellRenderer: function(params) {
            //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
            // }
          },
          {
            headerName: "Expense Status",
            field: "purchase_status",
            width: 165,
            filter: true,
            cellRenderer: function (params) {
              if (params.value == 0) {
                return `<div class="text-warning font-bold">Pending<div>`;
              } else if (params.value == 1) {
                return `<div class="text-success font-bold">Approved<div>`;
              } else if (params.value == 2) {
                return `<div class="text-danger font-bold">Rejected<div>`;
              }
            },
          },
          {
            headerName: "Name",
            field: "po_name",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "User",
            field: "user_name",
            minWidth: 200,
            maxWidth: 300,
            filter: true,
          },
          {
            headerName: "Details",
            field: "",
            filter: true,
            width: 150,
            cellRendererFramework: expenseDetails,
          },

          {
            headerName: "Amount",
            field: "formattedAmount",
            filter: true,
            width: 150,
          },
          {
            headerName: "TDS Amount",
            field: "tds_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Paid Amount",
            field: "tally_paid_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "VHC Code",
            field: "VHC_code",
            filter: true,
            width: 150,
          },
          {
            headerName: "Tally Error",
            field: "export_tally_error",
            filter: true,
            width: 150,
            hide: false,
          },
          {
            headerName: "Priority",
            field: "priority",
            filter: false,
            width: 110,
            cellRendererFramework: priority,
          },

          {
            headerName: "Multiple Images",
            field: "purchase_image",
            filter: true,
            width: 200,
            sortable: false,
            cellRendererFramework: imagePopUp,
          },
          {
            headerName: "Download",
            field: "purchase_id",
            filter: true,
            sortable: false,
            width: 140,
            cellRendererFramework: downloadInvoiceVue,
          },
        ];
      } else if (syncType == "Synced") {
        this.columnDefs = [
          {
            headerName: "Approve/Reject",
            field: "purchase_id",
            filter: false,
            width: 175,
            pinned: "left",
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: editPoOrder,
            // cellRenderer: function(params) {
            //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
            // }
          },
          {
            headerName: "Expense Status",
            field: "purchase_status",
            width: 165,
            filter: true,
            cellRenderer: function (params) {
              if (params.value == 0) {
                return `<div class="text-warning font-bold">Pending<div>`;
              } else if (params.value == 1) {
                return `<div class="text-success font-bold">Approved<div>`;
              } else if (params.value == 2) {
                return `<div class="text-danger font-bold">Rejected<div>`;
              }
            },
          },
          {
            headerName: "Name",
            field: "po_name",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "User",
            field: "user_name",
            minWidth: 200,
            maxWidth: 300,
            filter: true,
          },

          {
            headerName: "Details",
            field: "",
            filter: true,
            width: 150,
            cellRendererFramework: expenseDetails,
          },

          {
            headerName: "Amount",
            field: "formattedAmount",
            filter: true,
            width: 150,
          },
          {
            headerName: "TDS Amount",
            field: "tds_amount",
            filter: true,
            width: 150,
            hide: false,
          },
          {
            headerName: "Paid Amount",
            field: "tally_paid_amount",
            filter: true,
            width: 150,
            hide: false,
          },
          {
            headerName: "VHC Code",
            field: "VHC_code",
            filter: true,
            width: 150,
          },
          {
            headerName: "Tally Error",
            field: "export_tally_error",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Priority",
            field: "priority",
            filter: false,
            width: 110,
            cellRendererFramework: priority,
          },

          {
            headerName: "Multiple Images",
            field: "purchase_image",
            filter: true,
            width: 200,
            sortable: false,
            cellRendererFramework: imagePopUp,
          },
          {
            headerName: "Download",
            field: "purchase_id",
            filter: true,
            sortable: false,
            width: 140,
            cellRendererFramework: downloadInvoiceVue,
          },
        ];
      } else {
        this.columnDefs = [
          {
            headerName: "Approve/Reject",
            field: "purchase_id",
            filter: false,
            width: 175,
            pinned: "left",
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: editPoOrder,
            // cellRenderer: function(params) {
            //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
            // }
          },
          {
            headerName: "Expense Status",
            field: "purchase_status",
            width: 165,
            filter: true,
            cellRenderer: function (params) {
              if (params.value == 0) {
                return `<div class="text-warning font-bold">Pending<div>`;
              } else if (params.value == 1) {
                return `<div class="text-success font-bold">Approved<div>`;
              } else if (params.value == 2) {
                return `<div class="text-danger font-bold">Rejected<div>`;
              }
            },
          },
          {
            headerName: "Name",
            field: "po_name",
            minWidth: 200,
            filter: true,
          },
          {
            headerName: "User",
            field: "user_name",
            minWidth: 200,
            maxWidth: 300,
            filter: true,
          },
          {
            headerName: "Details",
            field: "",
            filter: true,
            width: 150,
            cellRendererFramework: expenseDetails,
          },

          {
            headerName: "Amount",
            field: "formattedAmount",
            filter: true,
            width: 150,
          },
          {
            headerName: "TDS Amount",
            field: "tds_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Paid Amount",
            field: "tally_paid_amount",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "VHC Code",
            field: "VHC_code",
            filter: true,
            width: 150,
          },
          {
            headerName: "Tally Error",
            field: "export_tally_error",
            filter: true,
            width: 150,
            hide: true,
          },
          {
            headerName: "Priority",
            field: "priority",
            filter: false,
            width: 110,
            cellRendererFramework: priority,
          },

          {
            headerName: "Multiple Images",
            field: "purchase_image",
            filter: true,
            width: 200,
            sortable: false,
            cellRendererFramework: imagePopUp,
          },
          {
            headerName: "Download",
            field: "purchase_id",
            filter: true,
            sortable: false,
            width: 140,
            cellRendererFramework: downloadInvoiceVue,
          },
        ];
      }

      let payload = {
        limit: this.paginationPageSize,
        type: 0,
        page_no: this.currentPage,
        tallySync: syncType,
      };
      let pID = this.project_value.project_id;
      InventoryService.getAllInventory(payload, pID)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.vendorsData = data.data;
            // this.totalPages = data.pagination.last_page;
            this.autoSizeAll();
          } else {
            this.$vs.notify({
              title: "No Data found for the Request",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }

          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectProject: function () {
      this.$vs.notify({
        title: "Select Project",
        iconPack: "feather",
        icon: "check_box",
        color: "warning",
      });
    },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    getAllInventoryList: function (currentPage) {
      // this.isPageNumber = currentPage;
      let filters = {
        page: currentPage,
      };
      let id = this.project_value.project_id;
      InventoryService.getAllInventory(filters, id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.vendorsData = data.data;

            // this.totalPages = data.pagination.last_page;
            // this.gridApi.sizeColumnsToFit();
          } else {
            this.$vs.notify({
              title: "No Data found for the Request",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjectsList: function () {
      ProjectService.getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.projects = data.data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchExp: function () {
      let isProjectSelect = Object.keys(this.project_value).length;
      // GET TEMP DATA API
      if (isProjectSelect > 0) {
        this.getAllInventoryList(this.currentPage);
      } else {
        this.$vs.notify({
          title: "Select Project",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },
  },
  mounted() {
    // if (this.checkPermission(["department", "GET"])) {
    //   this.getDepartmentList(this.currentPage);
    // }
    this.getProjectsList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
